import React from "react"
import { Helmet } from "react-helmet"

import Header from "src/components/headers/header"
import Footer from "src/components/footers/footer"
import Form from "src/components/formulaires/form-devis"


export default function index() {

  return (
    <div className="bg-white">
      <Helmet>
        <title>Demandez jusqu’à 3 devis pour votre étude de sol</title>
        <meta name="description" content="Devis pour une étude de sol | Vente terrain constructible loi Elan, étude préalable à construction (maison, extension, surélévation…), diagnostic géotechnique après sinistre, ANC" />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Header />
      </header>

      <main className="relative">
        <div className="relative bg-white">
          <div className="relative max-w-7xl mx-auto py-8 px-4">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
              Demandez jusqu’à 3 devis pour votre étude de sol
              </span>
            </h1>
            <div className="hidden lg:block">
              <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
              Vous souhaitez recevoir un ou plusieurs devis pour une étude de sol. Remplissez le formulaire ci-dessous et nous vous répondrons dans les plus brefs délais.
              <br />
              Besoin d'un bureau d’étude géotechnique ou bureau d’étude de sol ? Vente de terrain constructible loi Elan, étude préalable à construction (maison, extension, surélévation…), diagnostic géotechnique après sinistre, étude de sol pour un assainissement non collectif...
              <br />
              Géotechnique Maison.fr est le site expert de la mise en relation avec des bureaux d’étude partout en France. Envoyez-nous votre demande et nous vous répondrons dans les plus brefs délais. Recevez grâce à nous un ou plusieurs devis de la part de nos partenaires géotechniciens. Notre service de mise en relation est indépendant et entièrement gratuit.
              </p>
            </div>

          </div>
        </div>

        <Form />

      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  )
}